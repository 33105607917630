import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Cui from 'react-cui'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import ContentWrapper from '../components/share/contentWrapper'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "aboutUs/header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vector: file(relativePath: { eq: "aboutUs/vector.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    service: file(relativePath: { eq: "aboutUs/service.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    product: file(relativePath: { eq: "aboutUs/product.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    about: file(relativePath: { eq: "aboutUs/about.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    team: file(relativePath: { eq: "aboutUs/team.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    staff: file(relativePath: { eq: "aboutUs/staff.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 980) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    want: file(relativePath: { eq: "aboutUs/want.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    boats: file(relativePath: { eq: "aboutUs/boats.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    storm: file(relativePath: { eq: "aboutUs/storm.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    month: file(relativePath: { eq: "aboutUs/month.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class AboutUsPage extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                style={{ height: '600px' }}
                opacity="0.6"
                skew
              >
                <ContentWrapper>
                  <FontAwesomeIcon
                    icon={['fal', 'users']}
                    style={{ width: '25px', height: '25px', marginBottom: 10 }}
                  />
                  <BannerH6 style={{ marginBottom: 20, textAlign: 'start' }}>
                    Established in 2015 -{' '}
                    <strong>With a specific goal in mind...</strong>
                  </BannerH6>
                  <HeaderTitle>
                    ‘You can’t sell property, without Realhub’
                  </HeaderTitle>

                  <HeaderBody>
                    Operating on Sydney’s Northern Beaches, a real estate
                    marketing company serving agents, agencies and brands across
                    Australia with a user-focused, fast paced development team
                    and the quickest support team in the industry.
                  </HeaderBody>
                </ContentWrapper>
                <Vector
                  fluid={data.vector.childImageSharp.fluid}
                  style={{ position: 'absolute' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </ImageBackground>
              <ContentWrapper style={{ alignItems: 'center', marginTop: 150 }}>
                <BannerH4>
                  Our mission is to bring <strong> innovation </strong>
                  and <strong>better marketing </strong>, to every real estate
                  marketing campaign <strong>in the country. </strong>
                </BannerH4>
                <BannerH6>
                  A helping hand in real estate marketing, to build bigger,
                  smarter businesses together.
                </BannerH6>
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.service.childImageSharp.fluid}
                  title="Built on the back of direct service experience, a platform for all providers."
                  linkText="What services do we offer?"
                  link="/services"
                  content={
                    <Fragment>
                      <Paragraph>
                        Practically born into real estate marketing, and
                        operating on the ground level, from selling real estate
                        photography since before VPA (Vendor paid advertising)
                        was common to building successful print and sign
                        companies both interstate and here in Sydney, Realhub
                        was created to address gaps in the market, in both the
                        tools available to agents, and to providers.
                      </Paragraph>

                      <Paragraph>
                        Our experience, and our advantage comes from both
                        operating inside, and looking outside the real estate
                        marketing industry with a goal to shake up a sleeping
                        and oft neglected sector of the market.
                      </Paragraph>
                      <Paragraph>
                        A young team, bringing innovative ideas and problem
                        solving to our own systems and processes as service
                        providers grew into what today is one of the largest
                        real estate marketing platforms in the country.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  reverse
                  image={data.product.childImageSharp.fluid}
                  title="Change is inevitable, embrace it, or get left behind."
                  linkText="Read more about our innovative products..."
                  link="/proposals"
                  content={
                    <Fragment>
                      <Paragraph>
                        Renewed focus on the real estate market by the tech
                        industry in the last 3 years, and the potential of new
                        technologies to disrupt and change the way agencies
                        operate on a daily basis means you can’t throw a stone
                        without hitting a new ‘prop-tech’ company.
                      </Paragraph>

                      <Paragraph>
                        Welcoming the challenge of the existing competition and
                        new-comers we’ve grown our team, and through countless
                        revisions, thousands of new system updates and a hundred
                        and one new ideas managed to stay ahead of the curve and
                        continue to offer the best products and service in the
                        market.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ImageBackground
                height="500px"
                opacity="0.5"
                background={data.boats.childImageSharp.fluid}
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    descWidth="60%"
                    linkText="Who is already benefitting?"
                    link="/case-studies"
                    title="A rising tide, lifts all boats."
                    content={
                      <Fragment>
                        <Paragraph>
                          We’re aiming to bring innovation and better marketing
                          to every real estate sales campaign in the country by
                          building powerful tools that keep our agents ahead of
                          the curve, and beat the rising tide of tech
                          disruption, one campaign at a time.
                        </Paragraph>
                        <Paragraph>
                          Technology that’s built to help, and power. Not
                          replace. And we’re enabling agents to perform better
                          and focus on the personal touch every real estate
                          transaction needs.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>

              <ContentWrapper>
                <IntroRow
                  image={data.about.childImageSharp.fluid}
                  title="A bit about us..."
                  imgHeight="300px"
                  content={
                    <Fragment>
                      <Paragraph>
                        From small beginnings as a family enterprise of 3
                        brothers we’ve added new family members over the years.
                        By looking for people who share our vision for customer
                        centric solutions to real world problems with great
                        interpersonal communication skills who love a laugh
                        we’ve managed to grow a team of like minded friends who
                        work well together and support each other through the
                        high intensity day to day operations of supporting
                        thousands of busy real estate agents.
                      </Paragraph>
                      <Paragraph>
                        Our varied backgrounds combine to make up a team of
                        diverse skill sets, each contributing to the final
                        output we all pride ourselves on.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <Team fluid={data.team.childImageSharp.fluid} />
                <Staff fluid={data.staff.childImageSharp.fluid} />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.want.childImageSharp.fluid}
                  title="An idea we can all get behind"
                  linkText="Interested in joining? Check our careers page..."
                  link="https://realhub.betterteam.com/"
                  content={
                    <Fragment>
                      <Paragraph>
                        Realhub wouldn’t be what it is today without the
                        incredible hard work and contributions of our team past
                        and present, and the support of friends and family
                        throughout this time.
                      </Paragraph>

                      <Paragraph>
                        We’d like to extend a heartfelt thankyou to everyone
                        who’s helped us reach this position and put their energy
                        into the business and our family.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Some of the <strong>amazing companies</strong>, that{' '}
                    <strong>trust our team</strong> to do more to grow their
                    businesses across Australia.
                  </BannerH4>
                </FlexColumn>
              </ContentWrapper>
              <ContentWrapper>
                <BrandBanner />
              </ContentWrapper>

              <ContentWrapper
                style={{
                  alignItems: 'stretch',
                  margin: '90px auto 150px auto',
                  padding: '0 0 50px 0',
                }}
              >
                <Cui
                  uid="PQf1IG"
                  avatar="https://labs-assets.typeform.com/cui/avatars/PQf1IG/2693ac401953f90f07e5.jpg"
                />

                <StormGroup>
                  <Storm
                    fluid={data.storm.childImageSharp.fluid}
                    style={{ position: 'absolute' }}
                    imgStyle={{
                      objectFit: 'contain',
                      objectPosition: 'center right',
                    }}
                  />

                  <Month
                    fluid={data.month.childImageSharp.fluid}
                    style={{ position: 'absolute' }}
                    imgStyle={{
                      objectFit: 'contain',
                    }}
                  />
                </StormGroup>
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default AboutUsPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Staff = styled(Img)`
  width: 100%;
  height: 100%;
`

const Team = styled(Img)`
  width: 60%;
  margin: 20px auto;

  ${customMedia.greaterThan('mobile')`
    max-width: 250px;
  `};
`

const StormGroup = styled.div`
  position: relative;
`

const Storm = styled(Img)`
  position: absolute;
  right: 0;
  bottom: -230px;
  width: 250px;
  height: 250px;

  ${customMedia.between('mobile', 'tablet')`
    width: 200px;
    height: 300px;
    right: -100px;
    bottom: -100px;
    z-index: -1;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 200px;
    height: 350px;
    right: -160px;
    bottom: -100px;
  `};
`

const Month = styled(Img)`
  position: absolute;
  right: 70px;
  bottom: -180px;
  width: 75px;
  height: 75px;
  z-index: 1;

  ${customMedia.between('mobile', 'tablet')`
    width: 125px;
    height: 125px;
    right: -10px;
    bottom: -100px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 150px;
    height: 150px;
    right: -90px;
    bottom: -100px;
  `};
`

const Vector = styled(Img)`
  position: absolute;
  overflow: hidden;
  width: 300px;
  height: 300px;
  bottom: -140px;
  right: 15px;

  ${customMedia.between('mobile', 'tablet')`
    width: 350px;
    height: 350px;
    bottom: -180px;
    right: 320px;
  `};

  ${customMedia.greaterThan('desktop')`
    width: 450px;
    height: 450px;
    bottom: -220px;
    right: 250px;
  `};
`
